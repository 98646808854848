<template>
  <div>
    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"
            ><v-icon large>mdi-dropbox</v-icon></v-col
          >
          <v-col
            align="center"
            cols="11"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Composizione Pacchetti</v-col
          >
        </v-row>
      </template>

      <v-card-text>
        <v-row class="text-center">
          <v-col>
            <v-progress-circular
              v-if="fetchingFilters"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <div style="margin:15px">
          <v-row align="center" justify="center" v-if="!fetchingFilters">
            <v-col cols="6">
              <v-select
                dense
                v-model="filters.shipment"
                outlined
                item-text="name"
                item-value="id"
                :items="shipments"
                label="Spedizione"
                hide-details
              />
            </v-col>
          </v-row>
        </div>
        <div class="vertical-spacer" />

        <v-row class="text-center">
          <v-col>
            <v-progress-circular
              v-if="fetchingBundles"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" v-for="x in bundles" :key="x.id">
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col cols="1" class="pa-0"
                    ><v-icon>mdi-dropbox</v-icon></v-col
                  >
                  <v-col
                    align="center"
                    cols="11"
                    class="pa-0 card-title-text"
                    style="font-size: 18px"
                    >{{ x.source_name }}</v-col
                  >
                </v-row>
              </template>

              <v-card-text>
                <h2 class="secondary white--text">{{ x.description }}</h2>
                <span v-for="y in x.products" :key="y.id">
                  <h3>{{ y.name }}</h3>
                  {{ y.structure }}
                  <v-divider></v-divider>
                </span>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>
      </v-card-text>
    </base-material-card>
    <div style="height:40px" />
  </div>
</template>

<script>
import staticData from "../apis/staticData";
import subscriptionsManager from "../apis/subscriptions";
import productsManager from "../apis/products";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      loading: false,
      bundles: [],
      filters: {
        shipment: null
      },
      shipments: [],
      fetchingFilters: false,
      fetchingBundles: false
    };
  },

  components: {},

  mounted() {
    this.fetchFilters();
  },

  watch: {
    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadBundles();
        }, 300);
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    fetchFilters() {
      this.fetchingFilters = true;
      return new Promise((resolve, reject) => {
        subscriptionsManager
          .fetchShipmentsFilters()
          .then(result => {
            this.shipments = [
              { id: null, name: "Seleziona una spedizione", disabled: true },
              ...result.items
            ];
            this.fetchingFilters = false;
            resolve(result);
          })
          .catch(err => {
            this.fetchingFilters = false;
            reject(err);
          });
      });
    },

    reloadBundles() {
      if (this.filters.shipment) {
        this.fetchingBundles = true;
        let ctx = { filters: { shipment: this.filters.shipment } };
        productsManager
          .fetchBundlesAndProducts(ctx)
          .then(res => {
            this.bundles = res.items;
            this.fetchingBundles = false;
          })
          .catch(err => {
            console.log(err);
            this.fetchingBundles = false;
          });
      }
    }
  }
};
</script>
