var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":"1024","value":_vm.openDialog}},[_c('messageBox',{ref:"msgBox"}),_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dropbox")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"20px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.editing ? "Modifica transazione" : "Aggiungi transazione"))]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('v-card-text',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"body-2"},[_vm._v(" Transazione stripe: "+_vm._s(_vm.transaction.transaction_id)+" ")])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Nome","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.transaction.name),callback:function ($$v) {_vm.$set(_vm.transaction, "name", $$v)},expression:"transaction.name"}})]}}],null,true)})],1),(_vm.transaction.country == 'IT')?_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Codice Fiscale","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.transaction.fiscal_code),callback:function ($$v) {_vm.$set(_vm.transaction, "fiscal_code", $$v)},expression:"transaction.fiscal_code"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Telefono"},model:{value:(_vm.transaction.phone),callback:function ($$v) {_vm.$set(_vm.transaction, "phone", $$v)},expression:"transaction.phone"}})]}}],null,true)})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Totale"},model:{value:(_vm.transaction.amount),callback:function ($$v) {_vm.$set(_vm.transaction, "amount", $$v)},expression:"transaction.amount"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|email","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Email","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.transaction.email),callback:function ($$v) {_vm.$set(_vm.transaction, "email", $$v)},expression:"transaction.email"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Indirizzo","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.transaction.address),callback:function ($$v) {_vm.$set(_vm.transaction, "address", $$v)},expression:"transaction.address"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Città","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.transaction.city),callback:function ($$v) {_vm.$set(_vm.transaction, "city", $$v)},expression:"transaction.city"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Codice postale","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.transaction.postcode),callback:function ($$v) {_vm.$set(_vm.transaction, "postcode", $$v)},expression:"transaction.postcode"}})]}}],null,true)})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Stato"},model:{value:(_vm.transaction.state),callback:function ($$v) {_vm.$set(_vm.transaction, "state", $$v)},expression:"transaction.state"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Nazione","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.transaction.country),callback:function ($$v) {_vm.$set(_vm.transaction, "country", $$v)},expression:"transaction.country"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Descrizione","outlined":"","dense":""},model:{value:(_vm.transaction.description),callback:function ($$v) {_vm.$set(_vm.transaction, "description", $$v)},expression:"transaction.description"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"label":"Esenzione iva","items":_vm.vat_exemptions,"outlined":"","dense":"","item-text":"value","item-value":"id","error-messages":errors,"success":valid},model:{value:(_vm.transaction.vat_exemption),callback:function ($$v) {_vm.$set(_vm.transaction, "vat_exemption", $$v)},expression:"transaction.vat_exemption"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":_vm.editing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"label":"Esenzione iva","items":_vm.vat_exemption_texts,"outlined":"","dense":"","item-text":"value","item-value":"id","error-messages":errors,"success":valid},model:{value:(_vm.transaction.vat_exemption_texts),callback:function ($$v) {_vm.$set(_vm.transaction, "vat_exemption_texts", $$v)},expression:"transaction.vat_exemption_texts"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":invalid || !validated,"width":"120","color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("Conferma")]),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","width":"120","color":"secondary"},on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Annulla")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }