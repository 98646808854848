import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt from "jsonwebtoken";
import config from "../config";
import localStorageService from "../services/localStorageService";
import rightsServices from "../services/rights";
import avatars from "../services/avatars";
import usersManager from "../apis/users";
import i18n from "../plugins/i18n";
import { Settings } from "luxon";
import router from "../router";

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en";

Vue.use(Vuex);
// Reload user information from the cached authToken
let localAuthUser = null;
let localAccessToken = localStorageService.getAccessToken();
let localAvatar = localStorageService.getAvatar();
let notificationObject = localStorageService.getNotifications();
let localLocale = localStorageService.getLocale();
let localNotifications = [];
let cookiesVisible = localStorageService.getCookie();
let wc = localStorageService.getWC();

try {
  localNotifications = notificationObject ? JSON.parse(notificationObject) : {};
} catch (err) {
  localNotifications = [];
}

if (!localLocale) {
  localLocale = getNavigatorLanguage()
    ? getNavigatorLanguage().substr(0, 2)
    : "it";
  i18n.locale = localLocale;
} else {
  i18n.locale = localLocale;
}

if (localAccessToken) {
  let decodedToken = jwt.decode(localAccessToken);
  if (decodedToken) {
    localAuthUser = decodedToken.user;
    localAuthUser.avatar = localAvatar;
    localAuthUser.notifications = localNotifications;
    localAuthUser.locale = localLocale;
  }

  i18n.locale = localLocale;
}

let localData = localStorageService.getLocalData();

if (!localData)
  localData = {
    measure_units_by_id: {},
  };

if (!localAuthUser || !localData) {
  localAuthUser = {
    gravatar: null,
    first_name: "",
    last_name: "",
    id: 0,
    rights: [],
    avatar: localAvatar,
    email: "",
    notifications: [],
    idlekick: 1,
    locale: getNavigatorLanguage() ? getNavigatorLanguage().substr(0, 2) : "it",
  };
  i18n.locale = localAuthUser.locale;
}

let spoolerNumOfCopies = localStorageService.getSpoolerNumOfCopies();

Settings.defaultLocale = i18n.locale;

export default new Vuex.Store({
  state: {
    spooler_num_of_copies : spoolerNumOfCopies,
    global_overlay: null,
    global_overlay_text: null,
    drawer: null,
    loggingIn: false,
    backend: wc,
    cookiesVisible: cookiesVisible,
    user: {
      first_name: localAuthUser.first_name,
      last_name: localAuthUser.last_name,
      id: localAuthUser.id,
      rights: localAuthUser.rights,
      email: localAuthUser.email,
      avatar: localAvatar,
      gravatar: localAuthUser.gravatar,
      notifications: localAuthUser.notifications,
      locale: localAuthUser.locale,
      idlekick: localAuthUser.idlekick,
    },

    frontend: localData.frontend,
    versions: localData.versions,
    printer_spooler_auth: localData.printer_spooler_auth,
    spooler_online: false,

    hasRight: function(right) {
      return rightsServices.hasRight(this.user.rights, right);
    },

    isAdmin: function() {
      return rightsServices.isAdmin(this.user.rights);
    },

    isSuperAdmin: function() {
      return rightsServices.isSuperAdmin(this.user.rights);
    },
  },

  mutations: {
    switchWC(state, payload) {
      state.backend = payload;
      localStorageService.setWC(payload);
      router.go();
    },

    spoolerSetNumOfCopies(state,payload) {
      state.spooler_num_of_copies = payload.copies;
      localStorageService.setSpoolerNumOfCopies(state.spooler_num_of_copies);
    },

    spoolerStateOnline(state) {
      state.spooler_online = true;
    },

    spoolerStateOffline(state) {
      state.spooler_online = false;
    },

    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },

    loginStart: (state) => {
      state.loggingIn = true;
    },

    loginCompleted: (state, payload) => {
      if (payload.access_token && payload.refresh_token && payload.u) {
        localStorageService.setToken({
          access_token: payload.access_token,
          refresh_token: payload.refresh_token,
        });
        localStorageService.setAvatar(payload.avatar);
        localStorageService.setNotifications(
          JSON.stringify(payload.notifications)
        );
        state.user = payload.u;
        i18n.locale = state.user.locale;
        Settings.defaultLocale = i18n.locale;
        state.user.avatar = payload.avatar;
        state.user.notifications = payload.notifications;
        if (!state.user.locale)
          state.user.locale = getNavigatorLanguage()
            ? getNavigatorLanguage().substr(0, 2)
            : "it";
      } else {
        state.user = {};
        localStorageService.clearToken();
      }
      state.loggingIn = false;
    },

    logoutCompleted: (state) => {
      state.loggingIn = false;
      state.user = {};
      localStorageService.clearToken();
    },

    staticDataCompleted: (state, payload) => {
      payload.measure_units_by_id = {};
      payload.measure_units.map((x) => {
        payload.measure_units_by_id[x.id] = x.name;
      });

      state.frontend = payload.frontend;
      state.measure_units = payload.measure_units;
      state.measure_units_by_id = payload.measure_units_by_id;
      state.printer_spooler_auth = payload.printer_spooler_auth;

      localStorageService.setLocalData(payload);
    },
  },

  actions: {
    spoolerSetNumOfCopies: ({ commit }, payload) => {
      commit("spoolerSetNumOfCopies",payload);
    },

    spoolerOnline: ({ commit }) => {
      commit("spoolerStateOnline");
    },

    spoolerOffline: ({ commit }) => {
      commit("spoolerStateOffline");
    },

    impersonate({ commit }, payload) {
      return new Promise((resolve /*, reject*/) => {
        commit("loginCompleted", {
          access_token: payload.authToken,
          refresh_token: payload.refreshToken,
          u: payload.user,
          avatar: null,
          notifications: [],
        });

        resolve();
      });
    },

    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("loginStart");
        axios({
          url: config.apiEndPoint + "/auth/login",
          data: payload,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.responseError) {
              commit("loginCompleted", { error: resp.data.responseError });
              localStorageService.clearToken();
              reject(resp.data.responseError);
            } else {
              let accessToken = resp.data.responseData.authToken;
              let refreshToken = resp.data.responseData.refreshToken;
              let responseUser = resp.data.responseData.user;
              let notifications = resp.data.responseData.notifications;

              if (!accessToken || !payload || !refreshToken) {
                commit("loginCompleted", {});
                localStorageService.clearToken();
                if (resp.data.responseError) reject(resp.data.responseError);
                else reject("Invalid response from server");
              } else {
                localStorageService.setNotifications(notifications);
                if (responseUser.gravatar == true) {
                  avatars
                    .fetchGravatar(responseUser.email)
                    .then((result) => {
                      let responseAvatar = result;
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: responseAvatar,
                        notifications: notifications,
                      });
                      resolve(resp);
                    })
                    .catch((err) => {
                      console.log(err);
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: null,
                        notifications: notifications,
                      });
                      resolve(resp);
                    });
                } else if (responseUser.gravatar == false) {
                  // Fetch user profile picture
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                    notifications: notifications,
                  });
                  usersManager
                    .fetchAvatar(responseUser.id, accessToken)
                    .then((result) => {
                      this.state.user.avatar = result.avatar;
                      localStorageService.setAvatar(this.state.user.avatar);
                      resolve(resp);
                    })
                    .catch((err) => {
                      console.log(err);
                      resolve(resp);
                    });
                } else {
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                    notifications: notifications,
                  });
                  resolve(resp);
                }
              }
            }
          })
          .catch((err) => {
            commit("loginCompleted", { error: err });
            localStorageService.clearToken();
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: config.apiEndPoint + "/auth/logout",
          data: {},
          method: "POST",
        })
          .then(function() {
            commit("logoutCompleted");
            resolve();
          })
          .catch(function(error) {
            commit("logoutCompleted");
            reject(error);
          });
      });
    },

    unauthorized({ commit }) {
      return new Promise((resolve) => {
        commit("logoutCompleted");
        resolve();
      });
    },

    fetchStaticData({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: config.apiEndPoint + "/db/static", method: "POST" })
          .then((resp) => {
            commit("staticDataCompleted", resp.data.responseData);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
