var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"dark":"","app":"","src":_vm.background_image},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(10,10,10,.5), rgba(90,90,90,.8)"}},'v-img',props,false))]}}])},[_c('v-snackbar',{attrs:{"timeout":_vm.notification_timeout,"color":_vm.notification_severity,"top":""},model:{value:(_vm.notification_snackbar),callback:function ($$v) {_vm.notification_snackbar=$$v},expression:"notification_snackbar"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[(!_vm.notification || !_vm.notification.avatar_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-bell")]):_vm._e(),(_vm.notification && _vm.notification.avatar_id)?_c('div',[_c('div',{staticClass:"profile-container mr-2"},[(_vm.notification.gravatar)?_c('div',{staticClass:"profile-gravatar",domProps:{"innerHTML":_vm._s(_vm.buildGravatar(_vm.notification))}}):_vm._e()])]):_vm._e()],1)],1)],1),_c('v-btn',{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
        ? _vm.setDrawer(!_vm.drawer)
        : _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1),_c('span',{staticClass:"rimessa-logo-span"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"rimessa-logo",attrs:{"src":require("../assets/rimessa_logo_rosso.png")}})])],1),_c('span',{staticClass:"header-copyright"},[_vm._v(_vm._s(_vm.$t("wcs-copy", { version: _vm.packageInfo.version })))]),_c('v-spacer'),_c('div',{staticClass:"mx-3"}),_c('span',{staticClass:"header-welcome"},[_vm._v(" "+_vm._s(_vm.$t("wcs-welcome", { name: _vm.$store.state.user.first_name }))+" ")]),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"}):_c('app-bar-item',{key:("item-" + i)},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(p.title)},on:{"click":function($event){return _vm.handleClick(p)}}})],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }