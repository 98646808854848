import axios from "axios";
import config from "../config";
import store from "../store";
import localStorageService from "../services/localStorageService";
const path = require("path");

let products = new Object({
  downloadFile: (filePath /*name*/) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/filemanager/download?file=" +
          encodeURI(filePath) +
          "&token=" +
          encodeURI(token)
      );
      link.setAttribute("target", "_blank");
      let filename = path.basename(filePath);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  removeAttachment: (product_id, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/removeAttachment",
        data: {
          product_id: product_id,
          id: id
        },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addAttachment: (product_id, path) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/addAttachment",
        data: {
          product_id: product_id,
          path: path
        },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchProductAttachments: product_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetchProductAttachments",
        data: {
          product_id: product_id
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.attachments);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  loadWarehouse: (
    progressCallback,
    warehouse_amount,
    warehouse_location,
    product_id,
    price,
    formData
  ) => {
    return new Promise((resolve, reject) => {
      formData.append(
        "data",
        JSON.stringify({
          warehouse_amount: warehouse_amount,
          warehouse_location: warehouse_location,
          product_id: product_id,
          price: price
        })
      );

      axios
        .post(config.apiEndPoint + "/products/loadWarehouse", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: progressCallback.bind(this)
        })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  unloadWarehouse: (
    progressCallback,
    warehouse_amount,
    warehouse_location,
    product_id,
    price,
    formData
  ) => {
    return new Promise((resolve, reject) => {
      formData.append(
        "data",
        JSON.stringify({
          warehouse_amount: warehouse_amount,
          warehouse_location: warehouse_location,
          product_id: product_id,
          price: price
        })
      );

      axios
        .post(config.apiEndPoint + "/products/unloadWarehouse", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: progressCallback.bind(this)
        })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchMovements: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/movements",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.products,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchProducts: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetch",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.products,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBundles: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/fetch",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.bundles,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBundlesAndProducts: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/fullBundles",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.bundles,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  editProduct: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/edit",
        data: { product: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addProduct: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/add",
        data: { product: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeProduct: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/remove",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addBundle: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/add",
        data: { bundle: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  editBundle: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/edit",
        data: { bundle: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeBundle: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/remove",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBundleProducts: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/fetchProducts",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({ items: resp.data.responseData.products });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  searchBundleProduct: (id, filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/searchProducts",
        data: { id: id, filter: filter, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({ items: resp.data.responseData.products });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeBundleProduct: (bundle, product) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/removeProduct",
        data: {
          bundle: bundle,
          product: product
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addBundleProduct: (bundle, product) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/addProduct",
        data: {
          bundle: bundle,
          product: product
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setBundleProductQty: (bundle, product, qty) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/setQty",
        data: {
          bundle: bundle,
          product: product,
          qty: qty
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setBundleProductValue: (bundle, product, value) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/bundles/setValue",
        data: {
          bundle: bundle,
          product: product,
          value: value
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchWarehouses: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetchWarehouses",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve({
            warehouses: resp.data.responseData.warehouses
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchCategories: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetchCategories",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.categories,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addCategory: category => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/addCategory",
        data: { category: category },
        method: "POST"
      })
        .then(resp => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateCategory: category => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/updateCategory",
        data: { category: category, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeCategory: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/deleteCategory",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
});

export default products;
