<template>
  <div>
    <SetString ref="setString" />
    <v-dialog
      overlay-opacity="0.6"
      persistent
      max-width="600"
      :value="ordersDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col cols="1" class="pa-0"
                    ><v-icon large>mdi-dropbox</v-icon></v-col
                  >
                  <v-col
                    align="center"
                    cols="10"
                    class="pa-0 card-title-text"
                    style="font-size: 20px"
                    >Lista ordini</v-col
                  >
                  <v-col cols="1" class="pa-0 text-right"
                    ><v-icon @click="closeOrdersDialog" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>

              <v-card-text>
                <div class="text-center">
                  <v-progress-circular
                    v-if="fetchingOrders || buildingExport"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                </div>

                <v-row v-if="!fetchingOrders && !buildingExport">
                  <v-col>
                    <div style="overflow: scroll; height:400px">
                      <v-list two-line>
                        <v-list-item
                          v-for="order in orders"
                          :key="order.order_id"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-icon color="red">mdi-cart</v-icon>
                              <span
                                >{{ order.order_id }} -
                                {{ order.amount | toCurrencySymbol }} -
                                {{ order.order_date | toLocaleDate }} -
                                {{ order.renewal ? "Rinnovo" : "Principale" }} -
                                {{ order.status }}
                              </span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span
                                v-if="order.status == 'wc-refunded'"
                                style="color:Red"
                                >{{ "Ordine rimborsato" }}</span
                              >
                              <span
                                v-if="order.status == 'wc-failed'"
                                style="color:Red"
                                >{{ "Ordine fallito" }}</span
                              >
                              <span
                                v-if="order.status == 'wc-pending'"
                                style="color:Red"
                                >{{ "Ordine pending" }}</span
                              >
                              <span>{{ order.excerpt }}</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              :key="item.id"
                              v-for="item in order.items"
                              ><span>
                                <v-col
                                  >{{ item.item_name }}
                                  <v-tooltip bottom
                                    ><template v-slot:activator="{ on }"
                                      ><v-btn
                                        x-small
                                        text
                                        icon
                                        color="secondary"
                                        @click="overrideOrderItem(item)"
                                        v-on="on"
                                        ><v-icon>mdi-pencil</v-icon></v-btn
                                      ></template
                                    ><span>Modifica dati</span></v-tooltip
                                  > </v-col
                                ><v-col>Quantità: {{ item.qty }} </v-col></span
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog
      overlay-opacity="0.6"
      persistent
      max-width="500"
      :value="productsDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col cols="1" class="pa-0"
                    ><v-icon large>mdi-bottle-wine</v-icon></v-col
                  >
                  <v-col
                    align="center"
                    cols="10"
                    class="pa-0 card-title-text"
                    style="font-size: 20px"
                    >Lista prodotti</v-col
                  >
                  <v-col cols="1" class="pa-0 text-right"
                    ><v-icon @click="closeProductsDialog()" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <div style="overflow: scroll; height:400px">
                      <v-list two-line>
                        <v-list-item
                          v-for="product in products"
                          :key="product.product_id"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-icon color="red">mdi-bottle-wine</v-icon
                              >{{ product.qty }} x {{ product.name }}
                            </v-list-item-title>

                            <v-list-item-subtitle>
                              {{ product.structure }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog
      overlay-opacity="0.6"
      persistent
      max-width="500"
      :value="shippingDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col cols="1" class="pa-0"
                    ><v-icon large>mdi-dropbox</v-icon></v-col
                  >
                  <v-col
                    align="center"
                    cols="10"
                    class="pa-0 card-title-text"
                    style="font-size: 20px"
                    >Indirizzo di spedizione</v-col
                  >
                  <v-col cols="1" class="pa-0 text-right"
                    ><v-icon @click="closeShippingDialog" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>

              <v-card-text v-if="shippingItem">
                <v-row>
                  <v-col>
                    Nome:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_first_name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Cognome:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_last_name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Indirizzo:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_address_1 }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Indirizzo 2:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_address_2 }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Città:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_city }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Stato:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_state }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Codice Postale:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_postcode }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Nazione:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_country }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Compagnia:
                  </v-col>
                  <v-col>
                    {{ shippingItem.shipping_company }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Telefono:
                  </v-col>
                  <v-col>
                    {{ shippingItem.phone }}
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <messageBox ref="msgBox" />

    <SetString ref="setNotes" />

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"><v-icon large>mdi-lock</v-icon></v-col>
          <v-col
            align="center"
            cols="11"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Roscioli Wine Clubs Logistic Management System</v-col
          >
        </v-row>
      </template>
      <v-card-text>
        <v-row class="text-center">
          <v-col>
            <v-menu rounded="lg" offset-y>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="{ ...menu }">
                  CREA ESPORTAZIONE
                </v-btn>
              </template>

              <v-list>
                <v-list-item ripple v-for="x in warehouses" :key="x.id">
                  <v-list-item-title
                    ><span @click="makeExport(x.id)">{{ x.name }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              class="ma-2"
              v-if="!fetchingFilters && $store.state.hasRight('238')"
              @click="exportCsv()"
              color="primary"
              dark
              >Esporta CSV</v-btn
            >
            <v-btn
              class="ma-2"
              v-if="!fetchingFilters && $store.state.hasRight('239')"
              color="primary"
              @click="exportExcel()"
              dark
              >Esporta EXCEL</v-btn
            >

            <v-btn
              class="ma-2"
              v-if="!fetchingFilters"
              @click="exportOrdersCsv() && $store.state.hasRight('240')"
              color="primary"
              dark
              >Esporta CSV ORDINI</v-btn
            >
            <v-btn
              class="ma-2"
              v-if="!fetchingFilters"
              color="primary"
              @click="exportOrdersExcel() && $store.state.hasRight('241')"
              dark
              >Esporta EXCEL ordini</v-btn
            >

            <v-progress-circular
              v-if="fetchingFilters"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <div style="margin:15px">
          <v-row v-if="!fetchingFilters">
            <v-col>
              <v-text-field
                outlined
                v-model="filter"
                append-icon="mdi-magnify"
                class="mr-auto ml-1 pl-1"
                label="Ricerca"
                hide-details
                dense
              />
            </v-col>
            <v-col>
              <v-select
                dense
                :disabled="filters.shipment == null"
                v-model="filters.exported"
                outlined
                item-text="name"
                item-value="id"
                :items="export_states"
                label="Stato esportazione"
                hide-details
            /></v-col>

            <v-col>
              <v-select
                dense
                v-model="filters.shipment"
                outlined
                item-text="name"
                item-value="id"
                :items="shipments"
                label="Spedizione"
                hide-details
              />
            </v-col>
            <v-col>
              <v-select
                dense
                v-model="filters.woostate"
                outlined
                item-text="name"
                item-value="id"
                :items="woocommerce_states"
                label="Stato Woocommerce"
                hide-details
                multiple
              />
            </v-col>
            <v-col>
              <v-select
                dense
                v-model="filters.payments"
                outlined
                item-text="name"
                item-value="id"
                :items="payments"
                label="Numero pagamenti"
                hide-details
              />
            </v-col>

            <v-col>
              <v-select
                dense
                v-model="filters.suspended"
                outlined
                item-text="name"
                item-value="id"
                :items="suspend_states"
                label="Stato sospensione"
                hide-details
              />
            </v-col>
            <v-col>
              <v-select
                dense
                v-model="filters.manual"
                outlined
                item-text="name"
                item-value="id"
                :items="manual_states"
                label="Stato manuale"
                hide-details
              />
            </v-col>
          </v-row>
        </div>
        <div class="vertical-spacer" />
        <v-data-table
          v-model="selected_subscriptions"
          :show-select="filters.shipment != null"
          v-if="!fetchingFilters"
          :items-per-page="perPage"
          :must-sort="true"
          :headers="headers"
          :items="subscriptions"
          :options.sync="options"
          :server-items-length="totalSubscriptions"
          :loading="loading"
          :footer-props="dataTableFooterOptions"
          item-key="id"
          show-expand
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              Commenti : {{ item.excerpt }}<BR /> Note : {{ item.notes }}
            </td>
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.last_name }} {{ item.first_name }} ({{
              item.subscription_id
            }})
          </template>

          <template v-slot:item.actions="{ item }">
            <div style="min-width:72px">
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('242')"
                    x-small
                    text
                    icon
                    color="secondary"
                    @click.stop="setMemo(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>Modifica note</span></v-tooltip
              >

              <v-tooltip v-if="!item.suspended" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('243')"
                    x-small
                    text
                    icon
                    color="success"
                    @click.stop="setSuspendState(item)"
                    v-on="on"
                    ><v-icon>mdi-account-cancel</v-icon></v-btn
                  ></template
                ><span>Sospendi sottoscrizione</span></v-tooltip
              >

              <v-tooltip v-if="item.suspended" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('243')"
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="removeSuspendState(item)"
                    v-on="on"
                    ><v-icon>mdi-account-check</v-icon></v-btn
                  ></template
                ><span>Rimuovi sospensione</span></v-tooltip
              >

              <v-tooltip
                v-if="!item.manual && $store.state.hasRight('244')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.isAdmin()"
                    x-small
                    text
                    icon
                    color="success"
                    @click.stop="setManualState(item)"
                    v-on="on"
                    ><v-icon>mdi-engine-off</v-icon></v-btn
                  ></template
                ><span>Attiva spedizione manuale</span></v-tooltip
              >

              <v-tooltip
                v-if="item.manual && $store.state.hasRight('244')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.isAdmin()"
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="removeManualState(item)"
                    v-on="on"
                    ><v-icon>mdi-engine</v-icon></v-btn
                  ></template
                ><span>Rimuovi spedizione manuale</span></v-tooltip
              >
            </div>
          </template>

          <template v-slot:item.invoices_id="{ item }">
            <v-tooltip
              v-if="item.invoices_id && item.invoices_id.split(',').length > 0"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="downloadInvoices(item)"
                  v-on="on"
                  ><v-icon>mdi-download</v-icon>({{
                    item.invoices_id.split(",").filter(x => {
                      return x.length;
                    }).length
                  }})</v-btn
                ></template
              ><span
                >Scarica fatture ({{
                  item.invoices_id.split(",").filter(x => {
                    return x.length;
                  }).length
                }})</span
              ></v-tooltip
            >
          </template>

          <template v-slot:item.date="{ item }">
            {{ item.date | toLocaleDateTime }}
          </template>

          <template v-slot:item.status="{ item }">
            <div style="min-width:120px">
              <v-icon :color="getItemColor(item.status)">mdi-circle</v-icon>
              {{ getItemStatus(item.status) }}
            </div>
          </template>

          <template v-slot:item.schedule_next_payment="{ item }">
            {{ item.schedule_next_payment | toLocaleDateTime }}
          </template>

          <template v-slot:item.total_payments="{ item }">
            ({{ item.total_payments }})
          </template>

          <template v-slot:item.story_amount="{ item }">
            {{ item.story_amount | toCurrencySymbol }}
          </template>

          <template v-slot:item.amount="{ item }">
            {{ item.amount | toCurrencySymbol }}
          </template>

          <template v-slot:item.bundles="{ item }">
            <span v-html="formatBundles(item.bundles)"></span>
          </template>

          <template v-slot:item.shipping="{ item }">
            <span class="linked_text" @click.stop="showShipping($event, item)"
              >{{ item.shipping_city }} - {{ item.shipping_country }}</span
            >
          </template>

          <template v-slot:item.total_products="{ item }">
            <span
              v-if="filters.shipment && item.products.length"
              class="linked_text"
              @click.stop="showProducts($event, item)"
              >{{ item.total_products }}</span
            >
            <v-icon
              v-if="filters.shipment && !item.products.length"
              color="red"
            >
              mdi-alert</v-icon
            >
          </template>

          <template v-slot:item.total_orders="{ item }">
            <span class="linked_text" @click.stop="showOrders($event, item)"
              >{{ item.total_orders }}
              <span
                v-html="
                  item.refunded_orders > 0
                    ? '<BR/> Rimborsi: ' + item.refunded_orders
                    : ''
                "
              />
              <span
                v-html="
                  item.failed_orders > 0
                    ? '<BR/> Ordini falliti: ' + item.failed_orders
                    : ''
                "
              />
            </span>
            <v-tooltip bottom
              ><template v-slot:activator="{ on }">
                <v-icon v-if="item.refunded_orders > 0" v-on="on" color="error"
                  >mdi-alert-rhombus-outline</v-icon
                > </template
              ><span>{{ "Ordini rimborsati presenti" }}</span></v-tooltip
            >
            <v-tooltip bottom
              ><template v-slot:activator="{ on }">
                <v-icon v-if="item.failed_orders > 0" v-on="on" color="error"
                  >mdi-alert-rhombus-outline</v-icon
                > </template
              ><span>{{ "Ordini falliti presenti" }}</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }">
                <v-icon v-if="item.pending_orders > 0" v-on="on" color="error"
                  >mdi-alert-rhombus-outline</v-icon
                > </template
              ><span>{{ "Ordini pending presenti" }}</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }">
                <v-icon
                  v-if="
                    filters.shipment != null &&
                      item.export &&
                      item.total_orders < 2
                  "
                  v-on="on"
                  color="error"
                  >mdi-alert-rhombus-outline</v-icon
                > </template
              ><span>{{
                "Spedizione effettuata con meno di 2 pagamenti"
              }}</span></v-tooltip
            >
          </template>

          <template v-slot:item.excerpt="{ item }">
            <v-icon v-if="item.excerpt && item.excerpt.length" color="green"
              >mdi-dots-horizontal-circle-outline</v-icon
            >
          </template>

          <template v-slot:item.notes="{ item }">
            <v-icon v-if="item.notes && item.notes.length" color="green"
              >mdi-dots-horizontal-circle-outline</v-icon
            >
          </template>

          <template v-slot:item.shipment="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="item.export && filters.shipment != null"
                  color="green"
                  >mdi-check-circle-outline</v-icon
                > </template
              ><span v-if="item.export">{{
                "Già presente in export ID " + item.export.id
              }}</span></v-tooltip
            >

            <v-tooltip v-if="!item.export && !item.isSelectable" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="
                    !item.export &&
                      !item.manual &&
                      !item.isSelectable &&
                      filters.shipment != null
                  "
                  x-small
                  text
                  icon
                  color="secondary"
                  @click.stop="enableForcedExport(item)"
                  v-on="on"
                  ><v-icon>mdi-lock-open-alert</v-icon></v-btn
                ></template
              ><span>Abilita esportazione forzata</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }">
                <v-icon
                  v-if="!item.export && item.manual && filters.shipment != null"
                  v-on="on"
                  color="error"
                  >mdi-alert-rhombus-outline</v-icon
                > </template
              ><span>{{ "Spedizione in modalità manuale" }}</span></v-tooltip
            >
          </template>
        </v-data-table>
      </v-card-text>
      <div style="height:50px" />
    </base-material-card>
  </div>
</template>

<script>
import staticData from "../apis/staticData";
import subscriptionsManager from "../apis/subscriptions";
import AvatarServices from "../services/avatars";
import messageBox from "../components/MessageBox";
import SetString from "../components/SetString.vue";
import invoicesManger from "../apis/invoices";
import productsManager from "../apis/products";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      subscriptions: [],
      selected_subscriptions: [],
      buildingExport: false,
      loading: false,
      totalSubscriptions: 0,
      perPage: staticData.defaultPerPage,
      options: {
        sortBy: [this.$store.state.backend + "_rs_subscriptions.id"],
        sortDesc: [true],
        itemsPerPage: staticData.defaultPerPage
      },
      warehouses: [],
      headers: [],
      filter: null,
      filters: {
        shipment: null,
        suspended: false,
        woostate: null,
        manual: null,
        exported: null,
        payments: null
      },
      shipments: [],
      export_states: [
        { id: null, name: "Tutti" },
        { id: true, name: "Esportati" },
        { id: false, name: "Non esportati" }
      ],

      suspend_states: [
        { id: null, name: "Tutti" },
        { id: true, name: "Sospesi" },
        { id: false, name: "Non sospesi" }
      ],

      woocommerce_states: [
        { id: 1, name: "Attive" },
        { id: 2, name: "In attesa" },
        { id: 3, name: "Cancellate" },
        { id: 4, name: "In attesa cancellazione" },
        { id: 5, name: "Estinte" },
        { id: 6, name: "In attesa" }
      ],

      payments: [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
        { id: 6, name: "6+" },
        { id: null, name: "Tutte" }
      ],

      manual_states: [
        { id: null, name: "Tutti" },
        { id: true, name: "Manuali" },
        { id: false, name: "Automatici" }
      ],
      fetchingFilters: false,
      productsDialog: false,
      products: [],
      shippingDialog: false,
      shippingItem: null,
      orders: [],
      ordersDialog: null,
      fetchingOrders: false
    };
  },

  components: {
    messageBox,
    SetString
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    }
  },

  mounted() {
    this.headers = [
      { value: "shipment", sortable: false, text: "" },
      { text: "ID", align: "left", sortable: true, value: "id" },

      {
        text: "Stato",
        align: "left",
        sortable: true,
        value: "status"
      },
      { text: "Nome", align: "left", sortable: true, value: "name" },
      { text: "Email", align: "left", sortable: true, value: "email" },
      {
        text: "Data iscrizione",
        align: "left",
        sortable: true,
        value: "date"
      },

      {
        text: "Pacchetti",
        align: "left",
        sortable: true,
        value: "bundles"
      },
      {
        text: "Numero pagamenti",
        align: "center",
        sortable: true,
        value: "total_orders"
      },
      {
        text: "Fatture",
        align: "center",
        sortable: true,
        value: "invoices_id"
      },

      {
        text: "Totale",
        align: "center",
        sortable: true,
        value: "amount"
      },
      {
        text: "Totale storico",
        align: "center",
        sortable: true,
        value: "story_amount"
      },

      {
        text: "Destinazione",
        align: "center",
        sortable: true,
        value: "shipping"
      },
      {
        text: "Prodotti",
        align: "center",
        sortable: true,
        value: "total_products"
      },
      {
        text: "Prossimo pagamento",
        align: "left",
        sortable: true,
        value: "next_payment"
      },
      {
        text: "Commenti",
        align: "center",
        sortable: true,
        value: "excerpt"
      },
      {
        text: "Note",
        align: "center",
        sortable: true,
        value: "notes"
      },

      { text: "", align: "left", sortable: false, value: "actions" }
    ];

    this.fetchWarehouses().then(() => {
      this.fetchFilters();
    });
  },

  watch: {
    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadSubscriptions(true);
        }, 300);
      },
      deep: true
    },

    "filters.shipment": {
      handler() {
        this.filters.exported = null;
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadSubscriptions(true);
        }, 300);
      },

      deep: true,
      immediate: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadSubscriptions(true);
        }, 300);
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    fetchWarehouses() {
      this.fetchingFilters = true;
      return new Promise((resolve, reject) => {
        productsManager
          .fetchWarehouses()
          .then(result => {
            this.warehouses = result.warehouses;
            this.fetchingFilters = false;
            resolve();
          })
          .catch(err => {
            this.fetchingFilters = false;
            reject(err);
          });
      });
    },

    closeProductsDialog() {
      this.productsDialog = false;
    },

    overrideOrderItem: function(item) {
      this.$refs.setString
        .show(
          "Cambia nome pacchetto",
          "Nome pacchetto",
          "Nome pacchetto",
          item.item_name,
          null,
          true,
          false
        )
        .then(res => {
          subscriptionsManager
            .overrideOrderItem(item.id, res.value)
            .then(() => {
              item.item_name = res.value;
              this.reloadSubscriptions();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    enableForcedExport: function(item) {
      item.isSelectable = true;
    },

    getItemStatus: function(status) {
      if (status == "wc-on-hold") return "In attesa";
      else if (status == "wc-cancelled") return "Cancellata";
      else if (status == "wc-pending-cancel") return "In attesa cancellazione";
      else if (status == "wc-expired") return "Estinta";
      else if (status == "wc-pending") return "In attesa";
      else return "Attiva";
    },

    getItemColor: function(status) {
      if (status == "wc-on-hold") return "dark-yellow";
      else if (status == "wc-cancelled") return "red";
      else if (status == "wc-pending-cancel") return "orange";
      else if (status == "wc-expired") return "gray";
      else if (status == "wc-pending") return "dark-yellow";
      else return "green";
    },

    makeExport: async function(warehouse_id) {
      let invalid_products = false;
      const subscriptions = this.selected_subscriptions.map(x => {
        if (x.total_products == 0) invalid_products = true;
        return { id: x.subscription_id, products: x.products };
      });
      let continueOp = true;

      if (invalid_products) {
        try {
          await this.$refs.msgBox.show(
            "Uno o piu elementi selezionati non contengono un pacchetto valido. Se si continua, non sarà effettuato lo scarico automatico dal magazzino!",
            "Scarico magazzino",
            false
          );
        } catch (err) {
          continueOp = false;
        }
      }
      if (continueOp) {
        if (!invalid_products) {
          // Select the warehouse to download from
        }
        this.buildingExport = true;
        subscriptionsManager
          .buildExport(subscriptions, this.filters.shipment, warehouse_id)
          .then(() => {
            this.buildingExport = false;
            this.$refs.msgBox
              .show(
                "Export generato con successo e disponibile in archivio",
                "Creazione export",
                true
              )
              .then(() => {
                this.reloadSubscriptions(true);
              })
              .catch(() => {});
          })
          .catch(err => {
            console.log(err);
            this.buildingExport = false;
          });
      }
    },

    formatBundles: function(bundles) {
      let out = "";
      for (let n in bundles) {
        out += bundles[n].item_name + " x " + bundles[n].qty + "<BR/>";
      }
      return out;
    },

    setSuspendState: function(item) {
      subscriptionsManager
        .setSuspendState(item.subscription_id)
        .then(() => {
          item.suspended = true;
        })
        .catch(err => {
          console.log(err);
        });
    },

    removeSuspendState: function(item) {
      subscriptionsManager
        .removeSuspendState(item.subscription_id)
        .then(() => {
          item.suspended = false;
        })
        .catch(err => {
          console.log(err);
        });
    },

    setManualState: function(item) {
      subscriptionsManager
        .setManualState(item.subscription_id)
        .then(() => {
          item.manual = true;
          if (item.export) item.isSelectable = false;
          this.reloadSubscriptions(true);
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadInvoices(item) {
      let invoices = item.invoices_id.split(",").filter(element => element);
      invoicesManger
        .downloadInvoiceById(invoices)
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    },

    removeManualState: function(item) {
      subscriptionsManager
        .removeManualState(item.subscription_id)
        .then(() => {
          item.manual = false;
          if (!item.export) item.isSelectable = true;
          this.reloadSubscriptions(true);
        })
        .catch(err => {
          console.log(err);
        });
    },

    setMemo: function(item) {
      this.$refs.setNotes
        .show(
          "Imposta note",
          "Note",
          "Inserisci le note per la subscription",
          item.notes,
          item,
          false,
          false
        )
        .then(res => {
          let notes = res.value;
          subscriptionsManager
            .setNotes(item.subscription_id, notes)
            .then(() => {
              item.notes = notes;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    exportCsv: function() {
      subscriptionsManager.downloadCSV(this.filter, this.filters);
    },
    exportExcel: function() {
      subscriptionsManager.downloadEXCEL(this.filter, this.filters);
    },
    exportOrdersCsv: function() {
      subscriptionsManager.downloadOrdersCSV(this.filter, this.filters);
    },
    exportOrdersExcel: function() {
      subscriptionsManager.downloadOrdersEXCEL(this.filter, this.filters);
    },

    fetchOrders: function(item) {
      return new Promise((resolve, reject) => {
        subscriptionsManager
          .fetchOrders(item.subscription_id, item.orders_id)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    showShipping: function(event, item) {
      this.shippingItem = item;
      this.shippingDialog = true;
    },

    showProducts: function(event, item) {
      this.products = item.products;
      this.productsDialog = true;
    },

    showOrders: function(event, item) {
      this.ordersDialog = true;
      this.fetchingOrders = true;
      this.fetchOrders(item)
        .then(res => {
          this.orders = res.items;
          this.fetchingOrders = false;
        })
        .catch(err => {
          console.log(err);
          this.ordersDialog = false;
          this.fetchingOrders = false;
        });
    },

    closeOrdersDialog: function() {
      this.ordersDialog = false;
    },

    closeProductDialog: function() {
      this.productsDialog = false;
    },

    closeShippingDialog: function() {
      this.shippingDialog = false;
    },

    can_act_on_item: function(item) {
      if (!this.$store.state.user) return false;
      if (
        this.$store.state.isAdmin() ||
        item.account_id == this.$store.state.user.id
      )
        return true;
      return false;
    },

    reloadSubscriptions: function(showLoader) {
      this.subscriptions = [];
      this.selected_subscriptions = [];
      this.fetchSubscriptions(showLoader)
        .then(data => {
          this.subscriptions = data.items.map(x => {
            let subscription = x;
            if (
              x.export ||
              x.manual ||
              x.total_orders < 2 /*|| x.status != null*/
            )
              subscription.isSelectable = false;
            else subscription.isSelectable = true;

            if (subscription.status == "wc-on-hold")
              subscription.isSelectable = false;
            else if (subscription.status == "wc-cancelled")
              subscription.isSelectable = false;
            else if (subscription.status == "wc-pending-cancel")
              subscription.isSelectable = false;
            else if (subscription.status == "wc-expired")
              subscription.isSelectable = false;

            let orders_states = subscription.orders_status.split(",");
            for (let y of orders_states) {
              if (
                y.length &&
                (y == "wc-pending" ||
                  y == "wc-pending-cancel" ||
                  y == "wc-cancelled" ||
                  y == "wc-failed" ||
                  y == "wc-refunded")
              )
                subscription.isSelectable = false;
            }
            return subscription;
          });

          this.totalSubscriptions = data.totalCount;
        })
        .catch(err => {
          console.log(err);
        });
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    formatDuration: function(item) {
      let format = "";
      let seconds = parseInt(Math.floor(item.duration));
      let hours = Math.floor(seconds / 60 / 60);
      if (hours < 1) hours = 0;
      seconds -= 60 * 60 * hours;
      let minutes = Math.floor(seconds / 60);
      seconds -= 60 * minutes;
      if (seconds < 1) seconds = 1;
      if (hours > 0) format += hours + ":";
      if (minutes > 0 && minutes.toString().length < 2) format += "0";
      if (minutes > 0) format += minutes + ":";
      else format += "00:";
      if (seconds.toString().length < 2) format += "0";
      format += seconds;
      return format;
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      }

      return "";
    },

    fetchFilters() {
      this.fetchingFilters = true;
      return new Promise((resolve, reject) => {
        subscriptionsManager
          .fetchShipmentsFilters()
          .then(result => {
            this.shipments = [{ id: null, name: "Tutti" }, ...result.items];
            this.fetchingFilters = false;
            resolve(result);
          })
          .catch(err => {
            this.fetchingFilters = false;
            reject(err);
          });
      });
    },

    fetchSubscriptions(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        if (ctx.sortBy == "id")
          ctx.sortBy = this.$store.state.backend + "_rs_subscriptions.id";
        ctx.sortDesc = ctx.sortDesc[0];
        subscriptionsManager
          .fetchSubscriptions(ctx)
          .then(result => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    }
  }
};
</script>
