import "./plugins";
import "./components/design";

import Vue from "vue";
import Vuetify from "vuetify";
import interceptors from "./services/interceptors";
import store from "./store";
import router from "./router";
import i18n from "./plugins/i18n";
import App from "./App.vue";

interceptors.setupInterceptors();

Vue.config.productionTip = false

const vuetify = new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },

  theme: {
    themes: {
      light: {
        primary: "#6d2533",
        secondary: "#bfae5c",
        accent: "#bfae5c",
        error: "#b52b10",
        info: "#ffdc51",
        success: "#6ca74b",
        warning: "#975730",
      },
      dark: {
        primary: "#885b31",
        secondary: "#424242",
        accent: "#593c20",
        error: "#c62d2d",
        info: "#ffa551",
        success: "#a7c62d",
        warning: "#f75d00",
      },
    },
  },
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


